<template async>
    <router-view class="router" />
<KeepAlive>
  <Footer />
</KeepAlive>
</template>
<script async>
export default {
  name: 'app-main',
  components: {
    Footer: require('@/components/Footer.vue').default
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */
*{
  text-decoration: none;
  overflow-x: hidden !important;
}
html,
body {
    margin: 0 !important;
    padding: 0 !important;
    color: #05255dde;
}
section {
    min-width: 90vw!important;
    max-width: 90vw!important;
    margin: 0 auto!important
}
h1,h2,h3,h4,h5,h6{
    overflow: hidden;
}
h1,h2,h3,h4,h5,h6,.title{
  font-family: "Teko", Sans-serif;
  text-transform: uppercase;
  color: #081762e3;
}
p,a,strong,li{
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: #081762e3
}
.title {
    font-size: 70px;
    font-weight: 500;
    line-height: 58px;
    letter-spacing: -2.9px;
}
ul{
  list-style-type: none;
}
.whatsapp-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        background-image: radial-gradient(at center center, #25D366 10%, #0dd134 90%);
        width: 48px;
        height: 48px;
        padding: 20px;
        font-size: 16px;
        border-radius: 100%;
        text-decoration: none;
        animation: pulse 2s infinite;
        box-shadow: 0px 0px 21px -6px #25D366;
        // animation: pisca 1.5s infinite;
        transition: background-color 0.3s ease;
        position: fixed;
        bottom: 2vh;
        right: 5vw;
        @media only screen and (max-width: 600px) {
          bottom: 2vh;
          right: 15vw;
        }
        z-index: 1000;
    }

    .whatsapp-button:hover {
        background-color: #128C7E;
    }

    .whatsapp-button i {
        margin-right: 10px;
        font-size: 20px;
    }
    svg,span{
    vertical-align: middle;
    }
</style>
