import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/servicos',
    name: 'servicos',
    component: () => import('../components/Servicos.vue')
  },
  {
    path: '/xml',
    name: 'xml',
    component: () => import('../components/xml.vue')
  },
  {
    path: '/:id',
    name: 'Key',
    component: () => import('../components/Galeria.vue'),
    meta: {
      title: 'Guincho em Hortolândia'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

// Update the document title based on the meta title and route parameter
router.beforeEach((to, from, next) => {
  document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.origin + to.fullPath)
  try {
    if (to.params.id !== undefined) {
      localStorage.setItem('id', to.params.id.replaceAll('-', ' ').replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase()
      }))
    }
    /*  document.querySelector('meta[property="og:url"]').setAttribute('content', window.location)
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.params.id.replaceAll('-', ' ').replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase()
    })) */
    if (to.params.id !== undefined) {
      const formattedTitle = `${to.params.id.replaceAll('-', ' ').replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase()
      })} - Ligue Agora! - ☎ (12) 98847-1233 - Chegamos em 15 Minutos`
      document.title = formattedTitle
    }
    if (document.title.length > 60) {
      document.title = `${to.params.id.replaceAll('-', ' ').replace(/(?:^|\s)\S/g, function (a) {
        return a.toUpperCase()
      })} - Ligue Agora! - ☎ (12) 98847-1233`

      if (document.title.length > 60) {
        document.title = `${to.params.id.replaceAll('-', ' ').replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase()
        })} - ☎ (12) 98847-1233`
      }
    }
  } catch (error) {
    alert(error)
  }
  next()
})

export default router
